"use client";
import { useEffect } from "react";
import {
    init,
    replayIntegration,
    replayCanvasIntegration,
    browserProfilingIntegration,
    browserTracingIntegration,
    isInitialized,
} from "@sentry/browser";
import { getEnv } from "@/src/utils/configUtils";

// 忽略一些自定义错误和第三方报错（动态插入js+npm包里面报错）
// TODO 这个规则可能需要改进
const IGNOR_ERRORS = [
    /is not an object/, //自定义类型错误
    "www.paypalobjects.com", //paypal  支付第三次错误，
    "instream/video/client", //第三方 setTimeout报错 m.clearTimeout is not a function
    "static/js/lib/eintegration", //第三方 报错Unexpected token o in JSON at position 项目里面没有这个
    /UnhandledRejection/, //未处理的promise reject，导致报错，没有使用catch处理导致，在_app.js里面监听并打印未处理信息,
    "NotFoundError", //第三方模块 报错
    /No available storage method found/, //第三方模块 localforage 报错，
    "$img is not defined", //第三方模块 怀疑爬虫
    /Cannot create property 'h' on boolean 'false'/, //第三方模块 photoswiper
    /Attempted to assign to readonly property/, //第三方模块
    /001 Request cannot be processed/, //第三方模块
    /Blocked a frame with origin/, //跨域问题
    /Cannot find function isEqualNode in object/,
    /timeout of 60000ms exceeded/, //超时问题
    "Network Error", //网络错误
    /No response from window - cleaned up/, //PayPal 报错
    "Request aborted", //PayPal 报错
    "Objects are not valid as a React child", //PayPal 报错
    "livechatinc.com", // 第三方，聊天窗
    "LC_Invite", // ..
    "tracking.js", // ..
    "open_chat_window", // 第三方，聊天窗
    // "node_modules/", // 第三方，node_modules里面的js
    "CustomEvent",
    "Load failed", // 苹果的设备都fetch报错会出现这个
    "Failed to fetch",
];
const SEND_SENTRY_REGS = [/\/pages\/[a-z|A-Z]\w+/, /\/commons\..+\.js/];

export default function SentryBrowser() {
    useEffect(() => {
        // 需要发送sentry的报错
        const SENTRY_DSN = getEnv("SENTRY_DSN");
        if (!SENTRY_DSN) return;
        const APP_ENV = getEnv("APP_ENV");
        const SENTRY_TRACES_SAMPLE_RATE =
            Number(getEnv("SENTRY_TRACES_SAMPLE_RATE")) || 0.1;
        const SITE = self.getSite();
        if (isInitialized()) return;
        init({
            dsn: SENTRY_DSN,
            ignoreErrors: IGNOR_ERRORS,
            environment: `${APP_ENV}-${SITE}`,
            // 采样率
            tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            profilesSampleRate: 0.1,
            tracePropagationTargets: [
                // 匹配域名前面没有字符，以规避对埋点请求携带当前路由全路径，导致请求携带了sentry的header的问题
                new RegExp(
                    "^(?:https?://)?" +
                        self.location.hostname.replace(/\./g, "\\.") +
                        "/?$",
                    "i",
                ),
            ],
            integrations: [
                replayIntegration({
                    // Additional SDK configuration goes in here, for example:
                    maskAllText: true,
                    blockAllMedia: true,
                }),
                replayCanvasIntegration(),
                browserTracingIntegration(),
                browserProfilingIntegration(),
            ],
            beforeSend: (event: any, hint: any) => {
                console.log("beforeSend");
                //return null 屏蔽第三方js
                try {
                    //原始报错信息
                    const { originalException } = hint;
                    //调用站信息
                    const { stack } = originalException;
                    const send = isSend(
                        stack,
                        event.exception.values,
                        SEND_SENTRY_REGS,
                        IGNOR_ERRORS,
                    );
                    return send ? event : null;
                } catch (e) {
                    return null;
                }
            },
        });
    }, []);

    return null;
}

function isSend(
    eStack: string,
    eValues: any,
    sendRules: any,
    ignoreRules: any,
) {
    if (!sendRules) return false;
    let send = true;
    // sendRules.forEach((reg: any) => {
    //     if (reg.test(eStack)) {
    //         send = true;
    //     }
    // });
    // 确认忽略
    ignoreRules &&
        ignoreRules.forEach((rule: any) => {
            let reg = new RegExp(rule);
            if (reg.test(eStack)) {
                send = false;
            }
            eValues.forEach((item: any) => {
                if (reg.test(item.value)) {
                    send = false;
                }
            });
        });
    return send;
}
