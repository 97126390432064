import { EMAIL_SUB, PERFORMACE_MONITOR, USER_COUNTRY } from "@/config/api";
import { HandleJavaCommonFetch } from "@/src/utils/fetch";
import HandlePhpFetch from "@/src/utils/fetch/PhpFetch";
import { prometheusAlertServer } from "@/src/utils/prometheusAlert";
import { handleGid } from "./CPUChannelData";

//获取服务器端时间
const getDate = () => {
    return Math.floor(Date.now() / 1000);
};
// 判断用户的国家
function getCountryInfo() {
    return HandlePhpFetch.get(USER_COUNTRY);
}

/**
 * data
 *   clientType 0:pc 1:移动端
 *   requestType 0:网页 1:接口
 *   siteId
 *   requestTimeCount
 *   requestUrl
 *   mountType: 1,2,3...
 */
interface IPerformanceRecord extends IPerformanceRecordWrapper {
    clientType: 0 | 1;
    requestType: 0 | 1;
    requestTimeCount: number;
}
const performanceRecord = (data: IPerformanceRecord) => {
    HandleJavaCommonFetch.post(PERFORMACE_MONITOR, data)
        .then((res) => {})
        .catch((err) => {});
};

/**
 * @param {string} requestUrl 接口地址
 * @param {string} isPc 1:pc 0:移动端
 */
interface IPerformanceRecordWrapper {
    requestUrl: string;
    mountType: number;
}
interface IPerformanceProps extends IPerformanceRecordWrapper {
    prom: Promise<{
        hot: any;
        news: any;
    }>;
}
enum DeviceType {
    pc = 1,
    mobile = 0,
}
const performanceRecordWrapper = (performanceProps: IPerformanceProps) => {
    const start = Date.now();
    const { prom, requestUrl, mountType } = performanceProps;
    return prom
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return Promise.reject(err);
        })
        .finally(() => {
            const requestTimeCount = Date.now() - start;
            performanceRecord({
                clientType: 1, // mobile
                requestType: 1,
                requestTimeCount,
                requestUrl,
                mountType,
            });
        });
};

const getCodeFromEmail = (data: Record<string, any>) => {
    return HandlePhpFetch.post(EMAIL_SUB, data).then((res) => {
        handleGid({
            userId: res.user_id,
            email: data.email,
        });
        return res;
    });
};

interface prometheus {
    host: string;
    error: string;
    path: string;
    statusCode: number;
    level?: string;
}

const prometheusAlert = (params: prometheus) => {
    return typeof window !== "undefined"
        ? fetch("api/prometheus", {
              method: "POST",
              body: JSON.stringify(params),
          })
        : prometheusAlertServer({
              req: { host: params.host },
              req_url: params.path,
              status_code: params.statusCode,
              annotations_description: `${params.error}`,
              level: params.level,
          });
};

export {
    getCountryInfo,
    getDate,
    performanceRecordWrapper,
    getCodeFromEmail,
    performanceRecord,
    prometheusAlert,
};
