import React, { useCallback, useMemo, useRef, useState } from "react";
import {
    FmInput,
    FmButton,
    FmToast,
    FmPicker,
    FmCheckbox,
} from "@/ui-component";
import Text from "../common/Text";
import Share from "./ShareWithCustomers";
import stl from "./register.module.scss";
import { AddFlow, emailValidate, flowSpcice } from "@/src/utils";
import { ISignInModalStateAndAction } from "@/store/modules/signInModals";
import classnames from "classnames";
import reddit from "@/src/components/LoadAnalysis/analysis/reddit";
import { setLocalItem } from "@/lib/commonService";
import { useTranslation } from "@/src/i18n/client";
import { registerin, tryGo } from "@/src/models/loginModel";
import { bindTouristId } from "@/src/models/basket";
import TelRegister from "./TelRegister";
import { useCommonContext } from "@/src/provider";
import { encryptVasl, splitString } from "@/src/utils/encrypt";
import { openChat } from "@/lib/chatOperation";
export const arr = [
    "If you forgot your password, please contact customer support or use the 'Forgot Password' option.",
];

import useAnalysis from "@/src/hooks/useAnalysis";
import { getSiteConfiguration } from "@/src/utils/configUtils";
interface IProps {
    loginCallback: ISignInModalStateAndAction["loginCallback"];
    hideAndSetLogin: () => Promise<void>;
    forgetPass: () => void;
}

const Register: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { t } = useTranslation(["login"]);
    const { site, host } = useCommonContext();
    const { commonAnalysis } = useAnalysis();

    const genderPicker = useMemo(
        () => [
            {
                value: "m",
                label: t("login:Mr"),
                classes: {
                    root: classnames(stl["item-picker"], "item-picker"),
                },
            },
            {
                value: "f",
                label: t("login:Miss"),
                classes: {
                    root: classnames(stl["item-picker"], "item-picker"),
                },
            },
            {
                value: "x",
                label: t("login:Mx"),
                classes: {
                    root: classnames(stl["item-picker"], "item-picker"),
                },
            },
        ],
        [t],
    );
    const registerNodeRef = useRef<HTMLDivElement>(null);
    const [toastVisible, setToastVisible] = useState(false);
    const [gender, setGender] = useState<string | number>(
        genderPicker[1].value,
    ); // 默认选择miss
    const [name, setName] = useState("");
    const [entry_telephone, setEntry_telephone] = useState("");
    const [country_id, setCountry_id] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phStatus, setPhStatus] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [passwordAgain, setPasswordAgain] = useState("");
    const [telChecked, setTelChecked] = useState<number | undefined>(undefined);
    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(true);
    const [passwordAgainError, setPasswordAgainError] = useState("");
    const [telephoneError, setTelephoneError] = useState("");
    const usState = useMemo(() => site === "us", [site]); // 美国有电话输入
    const [subscription, setSubscription] = useState<number[]>([4, 2, 3, 5, 1]);
    const isHideGender = getSiteConfiguration("isHideGender", host);

    //页面切换自动定位到当前页面的第一个输入框，不用input的autofocus：tab显示会出错，且浏览器兼容性不好。

    const emailBlur = useCallback(() => {
        setEmailError("");
        const res = emailValidate(email);
        res.error && setEmailError(t(res.message));
    }, [email, t]);

    const handleBlur = useCallback(
        (key: string) => {
            switch (key) {
                case "name":
                    setNameError(name ? "" : t("login:error.nameRequired"));
                    break;
                case "lastName":
                    setLastNameError(
                        lastName ? "" : t("login:error.lastNameRequired"),
                    );
                    break;
                case "password":
                    if (!password) {
                        setPasswordError(t("login:error.password"));
                    } else if (password.length < 5) {
                        setPasswordError(t("login:error.incorrect password"));
                    } else {
                        setPasswordError("");
                    }
                    break;
                case "passwordAgain":
                    if (!passwordAgain) {
                        setPasswordAgainError(t("login:error.confirmPswd"));
                        return;
                    } else if (passwordAgain.length < 5) {
                        setPasswordAgainError(
                            t("login:error.incorrect password"),
                        );
                    } else {
                        setPasswordAgainError("");
                    }
                    break;
                default:
            }
        },
        [lastName, name, password, passwordAgain, t],
    );

    const toggleShowPass = () => {
        setShowPassword(!showPassword);
    };

    const onChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
    ) => {
        error && setError("");
        switch (key) {
            case "name":
                setName(e.target.value);
                break;
            case "lastName":
                setLastName(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            case "password":
                setPassword(e.target.value);
                break;
            case "passwordAgain":
                setPasswordAgain(e.target.value);
                break;
            default:
        }
    };

    const onSubscriptionChange = (
        val: string | number | (string | number)[],
    ) => {
        setSubscription(val as number[]);
    };

    const handleTelChecked = useCallback((values: number | undefined) => {
        console.log("dddd", values);

        setTelChecked(values);
    }, []);
    const showRegisterNotice = useCallback(() => {
        const blurRes = ["name", "lastName", "password", "passwordAgain"];
        for (let key of blurRes) {
            handleBlur(key);
        }
        emailBlur();
    }, [emailBlur, handleBlur]);

    const register = useCallback(async () => {
        // 被禁用时点击弹出消息提示
        showRegisterNotice(); // 格式验证
        // 密码和确认密码验证
        if (password !== passwordAgain) {
            setError(t("login:error.passwordsDifferent"));
            registerNodeRef.current!.scrollTop = 0;
            return;
        }

        let usStateParams: Record<string, any> = {};
        try {
            if (usState) {
                // 美国特定属性
                const tryGoRes = await tryGo();
                const telparams = {
                    countryId: country_id,
                    telephone: entry_telephone ? Number(entry_telephone) : "",
                    emailAddress: email,
                    unsubscribe: Number(!!telChecked),
                };
                const [firstHalf = "", secondHalf = ""] = splitString(tryGoRes);
                usStateParams = {
                    sn: encryptVasl(),
                    trace_id: firstHalf, //trace_id 后端返回token
                    timer_id: secondHalf, //timer_id 后端返回token
                    telparms: telparams,
                };
            }

            if (telephoneError) {
                return;
            }
            setToastVisible(true);

            const res = await registerin({
                gender: isHideGender ? "m" : gender,
                first_name: name,
                last_name: lastName,
                password,
                email_address: email,
                subscription,
                ...usStateParams,
            });

            AddFlow.register(res.customers_id);
            flowSpcice.register(res.customers_ids);
            reddit.SignUp();
            setLocalItem("email", email);
            bindTouristId();
            await props.hideAndSetLogin();
            props.loginCallback?.();
            !props.loginCallback && location.reload();
            commonAnalysis.CompleteRegistration();
        } catch (e: any) {
            setError(e.toString().split(":"));
            registerNodeRef.current!.scrollTop = 0;
            window.scrollTo(0, 0);
            if (arr.includes(e)) {
                setIsShow(true);
            }
            window.scrollTo(0, document.body.scrollHeight);
        } finally {
            setToastVisible(false);
        }
    }, [
        showRegisterNotice,
        password,
        passwordAgain,
        t,
        usState,
        telephoneError,
        isHideGender,
        gender,
        name,
        lastName,
        email,
        subscription,
        props,
        commonAnalysis,
        country_id,
        entry_telephone,
        telChecked,
    ]);

    // create-btn can be clicked
    const btnCanbeClicked = useMemo(
        () =>
            name &&
            lastName &&
            email &&
            password &&
            passwordAgain &&
            !nameError &&
            !emailError &&
            !lastNameError &&
            !passwordError &&
            !passwordAgainError &&
            !error &&
            !telephoneError &&
            !phStatus,
        [
            email,
            emailError,
            error,
            lastName,
            lastNameError,
            name,
            nameError,
            password,
            passwordAgain,
            passwordAgainError,
            passwordError,
            phStatus,
            telephoneError,
        ],
    );

    const [pickerOpen, setPickerOpen] = useState(false);

    const openSupport = useCallback(() => {
        openChat(host);
        const liveChatDom = document.querySelector("#chat-widget-container");
        if (liveChatDom) {
            (liveChatDom as HTMLElement).style.cssText +=
                "z-index: 10000 !important;";
        }
    }, [host]);
    const handleSetError = (name: string) => {
        switch (name) {
            case "Name":
                setNameError("");
                break;
            case "LastName":
                setLastNameError("");
                break;
            case "Email":
                setEmailError("");
                break;

            case "Password":
                setEmailError("");
                break;
            case "PasswordAgain":
                setEmailError("");
                break;
            default:
        }
        setError("");
    };

    return (
        <>
            <div
                className={stl["firmoo-register-container"]}
                ref={registerNodeRef}
            >
                <p className={stl["firmoo-register-tips"]}>
                    {t("login:registerText")}
                </p>
                <div className={stl["firmoo-register-name"]}>
                    <Text
                        text={t("login:Title")}
                        type="discrible"
                        color="title"
                    />
                    <div className={stl["register-name-picker"]}>
                        {isHideGender ? null : (
                            <FmPicker
                                inputClassName={stl["picker-container"]}
                                dataSource={genderPicker}
                                open={pickerOpen}
                                confirm={"confirm"}
                                cancel={"cancel"}
                                childrenClassName={classnames(
                                    stl["picker-children"],
                                )}
                                onChange={(open, value) => {
                                    setPickerOpen(open);
                                    if (value) {
                                        setGender(value);
                                    }
                                }}
                                value={gender}
                            />
                        )}
                        <FmInput
                            value={name}
                            name={"name"}
                            onChange={(e) => onChange(e, "name")}
                            helperText={nameError}
                            error={!!nameError}
                            onBlur={() => handleBlur("name")}
                            onFocus={() => handleSetError("Name")}
                            placeholder={t("login:NameText")}
                            label
                            className={
                                stl[
                                    isHideGender
                                        ? "picker-children"
                                        : "register-name-input"
                                ]
                            }
                        />
                        <FmInput
                            value={lastName}
                            name={"lastName"}
                            onChange={(e) => onChange(e, "lastName")}
                            helperText={lastNameError}
                            error={!!lastNameError}
                            onBlur={() => handleBlur("lastName")}
                            onFocus={() => handleSetError("LastName")}
                            placeholder={t("login:lastNameText")}
                            label
                            className={stl["register-name-input"]}
                        />
                    </div>
                </div>
                <FmInput
                    label={t("login:Email")}
                    type="email"
                    value={email}
                    name={"email"}
                    onChange={(e) => onChange(e, "email")}
                    helperText={emailError}
                    onBlur={emailBlur}
                    onFocus={() => handleSetError("Email")}
                    className={stl["email-input"]}
                    placeholder={t("login:EmailText")}
                />
                <FmButton
                    onClick={toggleShowPass}
                    variant={"text"}
                    className={classnames(
                        stl["firmoo-register-password"],
                        stl["make-btn-more-higher"],
                        stl["show-password-btn"],
                    )}
                >
                    {!showPassword
                        ? t("login:hidePassword")
                        : t("login:showPassword")}
                </FmButton>
                <FmInput
                    value={password}
                    name={"password"}
                    label={t("login:Password")}
                    type={showPassword ? "password" : "text"}
                    onChange={(e) => onChange(e, "password")}
                    helperText={passwordError}
                    onBlur={() => handleBlur("password")}
                    onFocus={() => handleSetError("Password")}
                    placeholder={t("login:PasswordText")}
                />
                <FmInput
                    label={t("login:ConfirmPassword")}
                    value={passwordAgain}
                    name={"passwordAgain"}
                    type={showPassword ? "password" : "text"}
                    onChange={(e) => onChange(e, "passwordAgain")}
                    helperText={passwordAgainError}
                    onBlur={() => handleBlur("passwordAgain")}
                    onFocus={() => handleSetError("PasswordAgain")}
                    placeholder={t("login:PasswordText")}
                />
                {usState ? (
                    <>
                        <TelRegister
                            sendDataTelephone={(tel) => {
                                if (!tel) {
                                    setTelChecked(undefined);
                                }
                                setEntry_telephone(tel);
                            }}
                            sendDataPhStadus={(phstatus) => {
                                setPhStatus(phstatus);
                            }}
                            sendDataCountryId={(countryId) => {
                                setCountry_id(countryId);
                            }}
                            sendDataError={(telephoneError) =>
                                setTelephoneError(telephoneError)
                            }
                        />
                        <FmCheckbox
                            dataSource={[
                                {
                                    checkboxDisabled: !entry_telephone,
                                    value: 1,
                                    label: t("login:receive"),
                                    labelClassName: stl["label-container"],
                                },
                            ]}
                            className={stl["checked-receive-container"]}
                            values={telChecked}
                            onChange={(value) =>
                                handleTelChecked(value as undefined | number)
                            }
                        />
                        <div
                            style={{
                                textAlign: "left",
                                marginTop: "0.4rem",
                                fontSize: "0.24rem",
                                color: "#8e9399",
                            }}
                        >
                            {t("login:automated")}
                        </div>
                    </>
                ) : null}
                <Share
                    onChange={onSubscriptionChange}
                    subscription={subscription}
                />
                <div
                    className={classnames(stl["firmoo-register-error"], {
                        ["hidden"]: !error,
                    })}
                >
                    {error}
                </div>
                <FmButton
                    disabled={!btnCanbeClicked}
                    onClick={register}
                    className={classnames(
                        stl["create-btn"],
                        stl["make-btn-more-higher"],
                        stl["fm-btn"],
                    )}
                    fullWidth
                    size={"large"}
                >
                    {t("login:CreateBtn")}
                </FmButton>
                {usState && isShow ? (
                    <div className={stl["registered-email-dangerous"]}>
                        <FmButton variant="text" onClick={openSupport}>
                            {t("login:support")}
                        </FmButton>
                        <FmButton
                            variant="text"
                            onClick={() => props.forgetPass()}
                        >
                            {t("login:Forgot")}
                        </FmButton>
                    </div>
                ) : null}
                {/* loading */}
                <FmToast visible={toastVisible} type={"loading"} />
            </div>
            {props.children}
        </>
    );
};

export default Register;
