import React, { useCallback, useEffect, useState } from "react";
import FacebookLoginBt from "./FacebookLogin";
import { ExtendButton, FmToast } from "@/ui-component";
import stl from "./fbLogin.module.scss";
import classnames from "classnames";
import { ISignInModalStateAndAction } from "@/store/modules/signInModals";
import { IStyleProps } from "@/src/app/interface";
//防止某些浏览器不能FB登陆
import FacebookBtn from "./SyncFacebookLogin";
import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";
import { useTranslation } from "@/src/i18n/client";
import { facebookLogin, recordLogin } from "@/src/models/loginModel";
import { bindTouristId } from "@/src/models/basket";
import { useToastContext } from "@/src/provider";
import { useFBLoading } from "@/src/hooks/useFBLoading";

interface IProps extends IStyleProps {
    loginCallback: ISignInModalStateAndAction["loginCallback"];
    hideAndSetLogin?: () => void;
}

const FBLogin: React.FC<IProps> = (props) => {
    const { t } = useTranslation(["common", "login"]);
    const [isIns, setIsIns] = useState(true);
    const [toastChildren, setToastChildren] = useState("");
    const [toastVisible, setToastVisible] = useState(false);
    const { showReTry, showFBloading, onRetry } = useFBLoading();
    const { showToast, closeToast } = useToastContext();
    const handleResponse = useCallback(
        async (data: any) => {
            setToastVisible(false);
            showToast("", "loading", 60 * 1000);
            const now = Date.now();
            try {
                const facebookData = await facebookLogin(data.profile);
                closeToast();
                recordLogin(undefined, undefined, now, now, "1");
                acquisition.login("UserId ", facebookData.customersId);
                await bindTouristId();
                setToastChildren(t("common:signIn:LoginSuccess"));
                setToastVisible(true);
                props.hideAndSetLogin?.();
                if (props.loginCallback) {
                    props.loginCallback();
                } else {
                    location.reload();
                }
            } catch (e) {
                recordLogin(undefined, e || "error", now, now, "1");
                setToastChildren(JSON.stringify(e));
                setToastVisible(true);
            }
        },
        [closeToast, props, showToast, t],
    );

    const handleError = useCallback(
        (err: any) => {
            const now = Date.now();
            recordLogin(undefined, err || "error", now, now, "1");
            setToastChildren(t("common:signIn:FBError"));
            setToastVisible(true);
        },
        [t],
    );

    const handleChangeToast = useCallback((val: boolean) => {
        setToastVisible(val);
    }, []);

    useEffect(() => {
        const isIphoneIns =
            /Iphone/i.test(navigator.userAgent) &&
            /Instagram/i.test(navigator.userAgent);
        console.log(isIphoneIns);
        setIsIns(isIphoneIns);
        if (window && window.FB) {
            return;
        }
    }, []);
    return (
        <div
            className={classnames(stl["firmoo-login-fb"], props.className)}
            style={props.style}
        >
            {showFBloading && (
                <div style={{ marginRight: "0.2rem", marginTop: "0.45rem" }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        viewBox="0 0 30 30"
                        className={stl[`circle`]}
                    >
                        <circle
                            cx="15"
                            cy="15"
                            r="14"
                            stroke="#fff"
                            strokeWidth="2"
                            fill="transparent"
                            strokeDasharray="61.5752, 26.3894"
                        />
                    </svg>
                </div>
            )}
            {t("login:LoginFB")}
            <div className={stl["facebook-hidden"]}>
                {isIns ? (
                    <FacebookBtn />
                ) : (
                    <FacebookLoginBt
                        scope="email"
                        onSuccess={handleResponse}
                        onError={handleError}
                    />
                )}
            </div>
            {showReTry && (
                <ExtendButton onClick={onRetry}>
                    <div style={{ marginTop: "3px", marginLeft: "5px" }}>
                        <svg
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="8650"
                            width="28"
                            height="28"
                        >
                            <path
                                d="M715.434667 137.898667a42.666667 42.666667 0 1 0-60.330667 60.330666l15.061333 15.104H512a298.666667 298.666667 0 1 0 285.184 387.626667 42.666667 42.666667 0 1 0-81.493333-25.386667A213.333333 213.333333 0 1 1 512 298.666667h168.533333l-20.266666 20.266666a42.666667 42.666667 0 0 0 60.330666 60.330667l87.893334-87.893333a42.666667 42.666667 0 0 0 0-60.373334l-93.056-93.098666z"
                                fill="rgba(61, 88, 156, 1)"
                                p-id="8651"
                            ></path>
                        </svg>
                    </div>
                </ExtendButton>
            )}
            <FmToast
                visible={toastVisible}
                type={"info"}
                seconds={3}
                onChange={handleChangeToast}
            >
                {toastChildren}
            </FmToast>
        </div>
    );
};
export default FBLogin;
