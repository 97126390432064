import { CURRENCY_COOKIE_NAME } from "@/config/constConf";
import { getCookieItem } from "@/lib/commonService";
import { getEnv } from "@/src/utils/configUtils";
const G_CONVARSION_ID = 866886311;

//ga firmoo 商家id TODO 用到这个变量的函数没有被调用 所以暂时不处理这个
const FIRMOO_MERCHANT_ID =
    typeof window !== "undefined" &&
    window.process &&
    window.process.env &&
    window.process.env.MERCHANT_ID;

// 插入head
function insertLabelUnderHead(addAttr, label = "script") {
    const head = document.getElementsByTagName("HEAD").item(0);
    label = document.createElement(label);
    if (label === "script") label.type = "text/javascript";
    typeof addAttr === "function" && addAttr(label);
    head.appendChild(label);
}

// 所以的页面均有的ga 这个函数是在客户端才会运行的
export function insertAllPage() {
    const GAID = getEnv("GAID");
    const GA4_ID = getEnv("GA4_ID");
    const GAADId_1 = getEnv("GAADId_1");
    const GAADId_2 = getEnv("GAADId_2");
    let user_id = "";
    user_id = getCookieItem("user_id");
    //google 统计id
    const GAUAID = GAID || "UA-10257771-1";
    //google 广告统计 id
    // const GAADId_FIRST = GAADId_1 || 'AW-866886311';
    // const GAADId_SECOND = GAADId_2 || 'AW-851482942';

    const promise = new Promise((resovle, rej) => {
        const $script = require("scriptjs");
        const path = "https://www.googletagmanager.com/gtag/js?id=";
        let gaScripts = [];

        GAID && gaScripts.push($script(`${path}${GAID}`));
        GA4_ID && gaScripts.push($script(`${path}${GA4_ID}`));

        GAADId_1 && gaScripts.push($script(`${path}${GAADId_1}`));

        GAADId_2 && gaScripts.push($script(`${path}${GAADId_2}`));

        gaScripts.length &&
            Promise.all(gaScripts)
                .then((res) => {
                    insertLabelUnderHead((newScript) => {
                        newScript.type = "text/javascript";
                        newScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        window.gtag = gtag;
        var pageArgus = {
          'page_location': window.location.href,
          'page_path': window.location.pathname + window.location.search,
          'allow_enhanced_conversions': true
        }
        gtag('js', new Date());
      
        ${GAADId_1 && `gtag('config', '${GAADId_1}', pageArgus);`}
        ${GAADId_2 && `gtag('config', '${GAADId_2}', pageArgus);`}
        ${GAID && `gtag('config', '${GAID}', pageArgus);`}
        ${
            GA4_ID &&
            `gtag('config', '${GA4_ID}', ${
                user_id
                    ? `{
          'page_location': window.location.href,
          'page_path': window.location.pathname + window.location.search,
          'allow_enhanced_conversions': true,
          'user_id': '${user_id}'
        }`
                    : `{
          'page_location': window.location.href,
          'page_path': window.location.pathname + window.location.search,
          'allow_enhanced_conversions': true
        }`
            });`
        }
        `;
                    });
                    let timer = setInterval(() => {
                        if (window.gtag) {
                            console.log("ga 加载成功");
                            //ga增强型转化 参考https://support.google.com/google-ads/answer/13258081?sjid=6131727864584135974-EU#zippy=%2C%E9%85%8D%E7%BD%AE%E8%BD%AC%E5%8C%96%E9%A1%B5-google-%E4%BB%A3%E7%A0%81
                            localStorage.getItem("customers_email_address") &&
                                window.gtag("set", "user_data", {
                                    sha256_email_address: localStorage.getItem(
                                        "customers_email_address",
                                    ),
                                });
                            window.clearInterval(timer);
                            resovle();
                        }
                    }, 50);
                })
                .catch((e) => console.log(e));
        // gtag
    });

    return promise;
}
// 不传就是其他页面
export function insertByparams(customParams) {
    customParams = customParams || {
        ecomm_prodid: "",
        ecomm_pagetype: "other",
        ecomm_totalvalue: 0,
    };
    window.google_tag_params = {
        ...customParams,
    };
    window.google_conversion_id = G_CONVARSION_ID;
    window.google_custom_params = window.google_tag_params;
    window.google_remarketing_only = true;
    insertLabelUnderHead((newScript) => {
        newScript.type = "text/javascript";
        newScript.src = "//www.googleadservices.com/pagead/conversion.js";
    });
    insertLabelUnderHead((noScript) => {
        noScript.innerHTML = `
    <div style="display:inline;">
      <img height= "1" width="1" style="border-style:none;" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/866886311/?guid=ON&amp;script=0"/>
    </div>
  `;
    }, "noscript");
}

// 支付成功，暂时不加
export function checkoutSuccessGa(custom_params = {}) {
    const { totalPrice, shippingValue, order_id } = custom_params;
    window.google_conversion_id = G_CONVARSION_ID;
    window.google_conversion_language = "en";
    window.google_conversion_format = "3";
    window.google_conversion_color = "ffffff";
    window.google_conversion_label = "tHmUCOTtwm8Qp8WunQM";
    window.google_conversion_currency = totalPrice;
    window.google_remarketing_only = false;
    insertLabelUnderHead((newScript) => {
        newScript.type = "text/javascript";
        newScript.src = "//www.googleadservices.com/pagead/conversion.js";
    });
    insertLabelUnderHead((noScript) => {
        noScript.innerHTML = `
    <div style="display:inline;">
      <img height="1"
        width="1"
        style="border-style:none;"
        alt=""
        src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/866886311/?value=${totalPrice}?>&amp;currency_code=USD&amp;label=tHmUCOTtwm8Qp8WunQM&amp;guid=ON&amp;script=0" / >
      </div>
    `;
    }, "noscript");
    let timer = setInterval(() => {
        if (window.gtag) {
            window.gtag("event", "conversion", {
                send_to: "AW-866886311/tHmUCOTtwm8Qp8WunQM",
                value: totalPrice,
                currency: "USD",
                transaction_id: order_id,
            });
            window.gtag("event", "conversion", {
                send_to: "AW-851482942/yPwmCK_bt4MBEL6ygpYD",
                value: totalPrice,
                currency: "USD",
                transaction_id: order_id,
            });
        }
    }, 50);
}
//石全修改，替换原来的analytics.js统计方式，改为gtag.js统计方式 电子商务
//参考网址： https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#track_purchases
function gtagECommerce(custom_params) {
    if (custom_params.ecomm_pagetype === "purchase") {
        const {
            ecomm_products,
            order_id,
            totalPrice,
            shippingValue,
            event_label,
        } = custom_params;
        //组装itmes数据
        //具体参数说明 查看网址：https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#impression_data
        const purchaseItems = [];
        for (let i in ecomm_products) {
            let id = ""; //商品的唯一 ID/SKU
            let name = ""; //商品名称。
            let price = ""; //商品的购买价格
            let quantity = ""; //商品数量
            if (custom_params.type === 1) {
                // paypal 支付成功直接发送GA
                const product = ecomm_products[i];
                name = `${product.frame}(${product.color_number})`;
                id = product.frame;
                price = product.frame_price;
                quantity = product.quantity;
            } else {
                // 其他支付方式checkoutSuccess发送请求
                const product = ecomm_products[i][0];
                const { products_name } = product;
                let skuArr = products_name.split("(");
                let sku = skuArr[0] ? skuArr[0] : "errorSku";
                name = products_name;
                id = sku;
                price = product.final_price;
                quantity = product.products_quantity;
            }

            purchaseItems.push({
                id,
                item_id: id,
                name,
                item_name: name,
                price,
                quantity,
                list_position: Number(i + 1),
            });
        }

        gtag("event", "purchase", {
            transaction_id: order_id, // Transaction ID. Required.
            value: totalPrice, // Grand Total.
            affiliation: "Firmoo eyeglasses", // Affiliation or store name.
            tax: 0,
            shipping: shippingValue, // Shipping.
            items: purchaseItems,
            event_label,
            content_type: event_label,
            currency: getCookieItem(CURRENCY_COOKIE_NAME),
        });
    }
}
// 电子商务
export function eCommerce(custom_params) {
    gtagECommerce(custom_params);
}
//设置GA 交互于洋
export function setGaLang(lang = "en_US") {
    //设置语言
    insertLabelUnderHead((newScript) => {
        newScript.type = "text/javascript";
        newScript.innerHTML = `
    window.___gcfg = {
      lang: ${setGaLang}
    };
    `;
    });
}
// GA 集成“选择参与调查问卷”模块
// 参数说明：https://support.google.com/merchants/answer/7106244?hl=zh-Hans&ref_topic=7105160
export function gaShopEvaluate({
    order_id,
    customer_email,
    delivery_country_code,
    estimated_delivery_date,
}) {
    console.log("merchant_id", FIRMOO_MERCHANT_ID);
    console.log("order_id", order_id);
    console.log("customer_email", customer_email);
    console.log("delivery_country_code", delivery_country_code);
    console.log("estimated_delivery_date", estimated_delivery_date);
    // 先定义
    window.renderOptIn = function () {
        console.log("do----renderOptIn");
        window.gapi.load("surveyoptin", function () {
            window.gapi.surveyoptin.render({
                merchant_id: FIRMOO_MERCHANT_ID, //商家 ID Y
                order_id: order_id, //订单的唯一 ID。 Y
                email: customer_email, //客户的电子邮件地址 Y
                delivery_country: delivery_country_code, // 两个字母的国家/地区代码，用于指定客户订单的配送地址。此值必须采用 ISO 3166-1 alpha-2 格式。此字段请勿使用“ZZ”格式。例如，"US"。
                estimated_delivery_date: estimated_delivery_date, // "YYYY-MM-DD", //预计送达日期 其中“YYYY”表示年，“MM”表示月，“DD”表示日。
                // OPTIONAL
                //"opt_in_style": "OPT_IN_STYLE"
            });
        });
    };
    const $script = require("scriptjs");
    const scriptSrc =
        "https://apis.google.com/js/platform.js?onload=renderOptIn";
    $script(scriptSrc, function () {
        // console.log('load--renderOptIn');
        // try {
        //   window.renderOptIn = function () {
        //     console.log('do----renderOptIn');
        //     window.gapi.load('surveyoptin', function () {
        //       window.gapi.surveyoptin.render({
        //         "merchant_id": FIRMOO_MERCHANT_ID, //商家 ID Y
        //         "order_id": order_id, //订单的唯一 ID。 Y
        //         "email": customer_email, //客户的电子邮件地址 Y
        //         "delivery_country": delivery_country_code, // 两个字母的国家/地区代码，用于指定客户订单的配送地址。此值必须采用 ISO 3166-1 alpha-2 格式。此字段请勿使用“ZZ”格式。例如，"US"。
        //         "estimated_delivery_date": estimated_delivery_date, // "YYYY-MM-DD", //预计送达日期 其中“YYYY”表示年，“MM”表示月，“DD”表示日。
        //         // OPTIONAL
        //         //"opt_in_style": "OPT_IN_STYLE"
        //       })
        //     })
        //   }
        // } catch (e) {
        //   console.log(e);
        // }
    });
}
// 集成徽章代码 Google 顾客评价 (GCR) 徽章代码是您要为 Google 顾客评价集成实现的两个代码段中的第一个
export function gaGCRReviews() {
    window.renderBadge = function () {
        console.log("do----renderBadg");
        var ratingBadgeContainer = document.createElement("div");
        document.body.appendChild(ratingBadgeContainer);
        window.gapi.load("ratingbadge", function () {
            window.gapi.ratingbadge.render(ratingBadgeContainer, {
                // REQUIRED
                merchant_id: FIRMOO_MERCHANT_ID,
                // OPTIONAL
                position: "BOTTOM_RIGHT",
            });
        });
    };
    const $script = require("scriptjs");
    const scriptSrc =
        "https://apis.google.com/js/platform.js?onload=renderBadge";
    $script(scriptSrc, function () {
        // console.log('load--renderBadge');
        // try {
        //   window.renderBadge = function () {
        //     console.log('do----renderBadg');
        //     var ratingBadgeContainer = document.createElement("div");
        //     document.body.appendChild(ratingBadgeContainer);
        //     window.gapi.load('ratingbadge', function () {
        //       window.gapi.ratingbadge.render(
        //         ratingBadgeContainer, {
        //         // REQUIRED
        //         "merchant_id": FIRMOO_MERCHANT_ID,
        //         // OPTIONAL
        //         "position": "BOTTOM_RIGHT"
        //       });
        //     });
        //   }
        // } catch (e) {
        //   console.log(e);
        // }
    });
}
// 这些页面不跑通用的
const gaPathes = [
    "/",
    "/index",
    "/pc",
    "/specsubs",
    "/pc/specsubs",
    "/pc/ProductDetail",
    "/productDetail",
    "/pc/newlens",
    "/lens",
    "/newlens",
    "/pc/basket",
    "/basket",
    "/pc/checkout",
    "/checkout",
    "/pc/checkoutSuccess",
    "/checkoutSuccess",
];

export const includeGaPath = (pathname) => {
    if (gaPathes.includes(pathname)) return true;
    return false;
};

// 添加CMP
export const addCmpScript = () => {
    const CMPCODE = getEnv("CMPCODE");
    if (!CMPCODE) return;
    insertLabelUnderHead((newScript) => {
        newScript.type = "text/javascript";
        newScript.src = `https://cdn.consentmanager.net/delivery/autoblocking/${CMPCODE}.js`;
        newScript.setAttribute("data-cmp-ab", "1");
        newScript.setAttribute(
            "data-cmp-host",
            "b.delivery.consentmanager.net",
        );
        newScript.setAttribute("data-cmp-cdn", "cdn.consentmanager.net");
        newScript.setAttribute("data-cmp-codesrc", "1");
    });
};
